import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { toTitleCase } from '../../../../../helpers/functions/regularFunctions'

const AddMeal = ({
  meals,
  currentUserId,
  categoryChoice,
  dayOfWeek,
  dateSearchInput,
  apiPostCall,
  setAddMealModal,
  setSubmitted,
  submitted,
}) => {

  const [showMeal, setShowMeal] = useState(false)
  const [submitReady, setSubmitReady] = useState(false)
  const [selectedMeal, setSelectedMeal] = useState([])
  const [numServings, setNumServings] = useState('')

  async function handlePostMealToMealEntry() {
    await apiPostCall(
      'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entry/',
      {
        user: currentUserId,
        food: null,
        meal: selectedMeal[0]._id,
        category: categoryChoice,
        mealDay: dayOfWeek,
        mealDatetime: `${dateSearchInput}`,
        servingsEaten: numServings,
        isMeal: true,
        foodList: selectedMeal[0].foodList
      }
    )
      .then(function (response) {
        setAddMealModal(false)
        setSubmitted(!submitted)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSelectMeal = (e) => {
    setSelectedMeal(meals.filter((a) => a._id === e.currentTarget.id))
    setShowMeal(true)
  }

  const handleServingsChange = (e) => {
    setNumServings(e.target.value)
    if(parseFloat(e.target.value) && parseFloat(e.target.value) > 0){
      setSubmitReady(true)
    } else {
      setSubmitReady(false)
    }
  }

  const handleRemoveMeal = () => {
    setSelectedMeal([])
    setShowMeal(false)
    setSubmitReady(false)
    setNumServings('')
  }

  return (
    <div>
      <h1 style={{ fontSize: '14px', textAlign: 'center', marginTop: '20px' }}>
        Choose One Of Your Meals Below!
      </h1>
      <div>
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxHeight: '250px',
            overflowY: 'scroll',
            marginTop: '20px',
          }}
        >
          {meals
            .filter((c) => !selectedMeal.map((b) => b._id).includes(c._id))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((a) => (
              <li
                key={a._id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '2px 2%',
                  width: '96%',
                  height: '40px',
                }}
              >
                <Button
                  variant='outline-dark'
                  style={{ width: '10%', marginLeft: '2%' }}
                  id={a._id}
                  onClick={handleSelectMeal}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Card
                  className='border-0'
                  style={{
                    width: '40%',
                    margin: '10px 0 10px 2%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {toTitleCase(a.name)}
                </Card>
                <Card
                  className='border-0'
                  style={{
                    width: '25%',
                    margin: '10px 0 10px 10%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {`${Math.round(
                    a.foodList
                      .map((a) => a.food.calories * a.servings)
                      .reduce(function (a, b) {
                        return a + b
                      }, 0)
                  )} Calories`}
                </Card>
              </li>
            ))}
        </ul>
      </div>
      <div
        style={{
          width: '96%',
          margin: '20px 0 0 2%',
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        {showMeal && (
          <div
            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          >
            <div style={{ width: '90%' }}>
              <InputGroup style={{ height: '30px' }}>
                <InputGroup.Text
                  style={{
                    width: '75%',
                    fontSize: '14px',
                    justifyContent: 'center',
                  }}
                >
                  {selectedMeal[0].name}
                </InputGroup.Text>
                <Form.Control
                  style={{ fontSize: '12px' }}
                  placeholder='Servings...'
                  onChange={handleServingsChange}
                  value={numServings === 0 ? '' : numServings}
                />
              </InputGroup>
              <p style={{ marginTop: '10px' }}>                  
                {`${(selectedMeal[0].foodList
                    .map((a) => a.food.calories * a.servings)
                    .reduce(function (a, b) {
                      return a + b
                    }, 0)
                * numServings).toFixed(0)} Calories`}
              </p>
            </div>
            <Button
              variant='outline-dark'
              style={{
                width: '9%',
                marginLeft: '1%',
                padding: '0',
                height: '35px',
              }}
              onClick={handleRemoveMeal}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </div>
        )}
        {submitReady && (
          <Button variant='outline-dark' onClick={handlePostMealToMealEntry}>
            Submit Meal Entry
          </Button>
        )}
      </div>
    </div>
  )
}

export default AddMeal
