import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

const DailyExerciseTotals = ({ exercises, exerciseEntries, dateSearchInput }) => {
  const totalCaloriesBurned = exerciseEntries
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.caloriesBurned)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const totalMinutes = exerciseEntries
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.duration)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  const totalSets = exerciseEntries
    .filter((a) => String(a.exerciseDatetime).includes(dateSearchInput))
    .map((b) => b.sets)
    .reduce(function (a, b) {
      return a + b
    }, 0)

  console.log()

  const exerciseTypes = exercises
    .filter(
      (a) =>
        exerciseEntries
          .filter((b) => String(b.exerciseDatetime).includes(dateSearchInput))
          .map((c) => c.exercise._id)
          .indexOf(a._id) !== -1
    )
    .map((e) => e.muscleGroup + ', ')

  const uniqueExerciseTypes = [...new Set(exerciseTypes)]

  return (
    <div style={{ width: '100%', margin: '5px 0' }}>
      <Card style={{ backgroundColor: '#c0e2f9' }}>
        <Card.Title className='text-center'>
          Total Calories Burned: {totalCaloriesBurned}
        </Card.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceAround',
            margin: '0 0 15px 0',
          }}
        >
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '25%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1%',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Duration
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {totalMinutes} min
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '15%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Sets
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {totalSets}
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '56%',
              fontSize: '13px',
              textAlign: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Muscles
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {uniqueExerciseTypes.join('').slice(0, -2)}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export default DailyExerciseTotals
