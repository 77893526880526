import React from 'react'
import DailyExerciseTotals from './DailyTotalTypes/DailyExerciseTotals'
import DailyMealTotals from './DailyTotalTypes/DailyMealTotals'
import WeeklyExerciseTotals from './WeeklyTotalTypes/WeeklyExerciseTotals'
import WeeklyMealTotals from './WeeklyTotalTypes/WeeklyMealTotals'
import CustomExerciseTotals from './CustomTotalTypes/CustomExerciseTotals'
import CustomMealTotals from './CustomTotalTypes/CustomMealTotals'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const DailyTotals = ({
  mealEntries,
  exerciseEntries,
  dateSearchInput,
  foods,
  homemadeFoods,
  exercises,
  user,
  currentUserId,
  datetimeEntry
}) => {
  return (
    <div style={{ width: '90%', margin: '5px 5%' }}>
      <Tabs
        defaultActiveKey="daily"
        id="summaries"
        className="mb-3"
        justify
      >
        <Tab eventKey="daily" title="Daily Summary">
          <DailyMealTotals
            mealEntries={mealEntries}
            foods={foods}
            dateSearchInput={dateSearchInput}
            homemadeFoods={homemadeFoods}
            user={user}
            currentUserId={currentUserId}
            datetimeEntry={datetimeEntry}
          />
          <DailyExerciseTotals
            exercises={exercises}
            exerciseEntries={exerciseEntries}
            dateSearchInput={dateSearchInput}
          />
        </Tab>
        <Tab eventKey="weekly" title="Weekly Summary">
          <WeeklyMealTotals
            mealEntries={mealEntries}
            foods={foods}
            dateSearchInput={dateSearchInput}
            homemadeFoods={homemadeFoods}
            user={user}
            currentUserId={currentUserId}
            datetimeEntry={datetimeEntry}
          />
          <WeeklyExerciseTotals
            exercises={exercises}
            exerciseEntries={exerciseEntries}
            dateSearchInput={dateSearchInput}
            datetimeEntry={datetimeEntry}
          />
        </Tab>
        <Tab eventKey="custom" title="Custom Summary">
          <CustomMealTotals
            mealEntries={mealEntries}
            foods={foods}
            dateSearchInput={dateSearchInput}
            homemadeFoods={homemadeFoods}
            user={user}
            currentUserId={currentUserId}
            datetimeEntry={datetimeEntry}
            exercises={exercises}
            exerciseEntries={exerciseEntries}
          />
        </Tab>
      </Tabs>
    </div>
  )
}

export default DailyTotals
