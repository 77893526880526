import React, { useState, useEffect } from 'react'

import { ObjectId } from 'bson'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import AddFood from './mealEntryModalsComponents/AddFood'
import AddMeal from './mealEntryModalsComponents/AddMeal'
import AddFoodToDb from './mealEntryModalsComponents/AddFoodToDb'
import {
  apiGetCall,
  apiPostCall,
} from '../../../../helpers/functions/proxyServer'

const MealEntryModal = ({
  addMealModal,
  setAddMealModal,
  currentUserId,
  categoryChoice,
  dateSearchInput,
  dayOfWeek,
  submitted,
  setSubmitted,
}) => {
  const [tabKey, setTabKey] = useState('addFood')
  const [foods, setFoods] = useState([])
  const [filteredFoods, setFilteredFoods] = useState([])
  const [meals, setMeals] = useState([])
  const [resetDiv, setResetDiv] = useState(false)
  const [foodChoice, setFoodChoice] = useState([])
  const [mealNameChoice, setMealNameChoice] = useState('')
  const [mealDescriptionChoice, setMealDescriptionChoice] = useState('')

  useEffect(() => {
    apiGetCall('https://fisher-fitness-91bf435fdc2b.herokuapp.com/foods')
      .then((response) => {
        setFoods(response.data)
        setFilteredFoods(response.data)
      })
      .catch((error) => {
        console.error(error)
      })

    apiGetCall('https://fisher-fitness-91bf435fdc2b.herokuapp.com/meals')
      .then((response) => {
        setMeals(response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  async function handlePostMealEntry() {
    if (mealNameChoice !== '') {
      const newId = new ObjectId()
      await apiPostCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal/',
        {
          _id: newId,
          createdByUser: currentUserId,
          name: mealNameChoice,
          description: mealDescriptionChoice,
          foodList: foodChoice.map((a) => ({
            food: a._id,
            servings: a.numServings,
          })),
        }
      )
      await apiPostCall(
        'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entry/',
        {
          user: currentUserId,
          food: null,
          meal: newId,
          category: categoryChoice,
          mealDay: dayOfWeek,
          mealDatetime: `${dateSearchInput}`,
          servingsEaten: 1,
          isMeal: true,
          foodList: foodChoice.map((a) => ({
            food: a._id,
            servings: a.numServings,
          })),
        }
      )
      .then(function (response) {
        setFoodChoice([])
        setAddMealModal(false)
        setSubmitted(!submitted)
      })
      .catch(function (error) {
        console.log(error)
      })
    } else {
      for (let i = 0; i < foodChoice.length; i++) {
        await apiPostCall(
          'https://fisher-fitness-91bf435fdc2b.herokuapp.com/meal-entry/',
          {
            user: currentUserId,
            food: foodChoice[i]._id,
            meal: null,
            category: categoryChoice,
            mealDay: dayOfWeek,
            mealDatetime: `${dateSearchInput}`,
            servingsEaten: foodChoice[i].numServings,
            isMeal: false,
            foodList: null
          }
        )
          .then(function (response) {
            setFoodChoice([])
            setAddMealModal(false)
            setSubmitted(!submitted)
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }

  const checkDiv = (k) => {
    setTabKey(k)
    setResetDiv(!resetDiv)
  }

  return (
    <div>
      <Modal
        show={addMealModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Modal.Title
              style={{ width: '80%', textAlign: 'center', marginLeft: '10%' }}
            >
              <div style={{ width: '100%' }}>
                <h1 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {dayOfWeek}
                </h1>
                <h2 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {dateSearchInput}
                </h2>
                <h3 style={{ textAlign: 'center', fontSize: '16px' }}>
                  {categoryChoice}
                </h3>
              </div>
            </Modal.Title>
            <Button
              className='border-0'
              variant='outline-dark'
              style={{ width: '10%', marginLeft: '10%' }}
              onClick={() => setAddMealModal(false) + setFoodChoice([])}
            >
              <FontAwesomeIcon icon={faXmark} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: '5px' }}>
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => checkDiv(k)}
            className='mb-3'
            style={{ fontSize: '12px' }}
          >
            <Tab eventKey='addFood' title='Add Food'>
              <AddFood
                foods={foods}
                filteredFoods={filteredFoods}
                setFilteredFoods={setFilteredFoods}
                resetDiv={resetDiv}
                foodChoice={foodChoice}
                setFoodChoice={setFoodChoice}
                mealNameChoice={mealNameChoice}
                setMealNameChoice={setMealNameChoice}
                mealDescriptionChoice={mealDescriptionChoice}
                setMealDescriptionChoice={setMealDescriptionChoice}
                handlePostMealEntry={handlePostMealEntry}
              />
            </Tab>
            <Tab eventKey='addMeal' title='Add Meal'>
              <AddMeal
                meals={meals}
                resetDiv={resetDiv}
                currentUserId={currentUserId}
                categoryChoice={categoryChoice}
                dayOfWeek={dayOfWeek}
                dateSearchInput={dateSearchInput}
                apiPostCall={apiPostCall}
                setAddMealModal={setAddMealModal}
                setSubmitted={setSubmitted}
                submitted={submitted}
              />
            </Tab>
            <Tab eventKey='addFoodToDb' title='Add Food To Database'>
              <AddFoodToDb
                currentUserId={currentUserId}
                apiGetCall={apiGetCall}
                apiPostCall={apiPostCall}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default MealEntryModal
