import React from 'react'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import moment from 'moment'

const WeeklyExerciseTotals = ({ exercises, exerciseEntries, dateSearchInput, datetimeEntry }) => {
  
  const startingDate = moment(datetimeEntry).subtract(6, 'days')._d

  const daysLogged = 7

  const allEntries = exerciseEntries
    .filter((c) => c.exerciseDatetime.slice(0, 10) >= startingDate.toJSON().slice(0, 10))
    .filter((c) => c.exerciseDatetime.slice(0, 10) <= dateSearchInput)

  const avgCaloriesBurned = (allEntries
    .map((b) => b.caloriesBurned)
    .reduce(function (a, b) {
      return a + b
    }, 0) / daysLogged)
    .toFixed(0)

  const avgMinutes = (allEntries
    .map((b) => b.duration)
    .reduce(function (a, b) {
      return a + b
    }, 0) / daysLogged)
    .toFixed(0)

  const avgSets = (allEntries
    .map((b) => b.sets)
    .reduce(function (a, b) {
      return a + b
    }, 0) / daysLogged)
    .toFixed(0)

  const exerciseTypes = exercises
    .filter(
      (a) =>
        allEntries
          .map((c) => c.exercise._id)
          .indexOf(a._id) !== -1
    )
    .map((e) => e.muscleGroup + ', ')

  const uniqueExerciseTypes = [...new Set(exerciseTypes)]

  return (
    <div style={{ width: '100%', margin: '5px 0' }}>
      <Card style={{ backgroundColor: '#c0e2f9' }}>
        <Card.Title className='text-center'>
          Average Calories Burned: {avgCaloriesBurned}
        </Card.Title>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'spaceAround',
            margin: '0 0 15px 0',
          }}
        >
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '25%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 6%',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Avg. Duration
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {avgMinutes} min
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '16%',
              fontSize: '13px',
              alignItems: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Avg. Sets
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {avgSets}
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <Card
            className='bg-transparent border-0'
            style={{
              height: '40px',
              width: '50%',
              fontSize: '13px',
              textAlign: 'center',
              margin: '0 1% 0 0',
            }}
          >
            <ListGroup>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{ height: '20px', padding: '0' }}
              >
                Muscles
              </ListGroup.Item>
              <ListGroup.Item
                className='bg-transparent border-0'
                style={{
                  height: '20px',
                  padding: '0',
                  margin: '0 0 2px 0',
                  textAlign: 'center',
                }}
              >
                {uniqueExerciseTypes.join('').slice(0, -2)}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export default WeeklyExerciseTotals
