import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import { toTitleCase } from '../../../../../helpers/functions/regularFunctions'

const AddFood = ({
  foods,
  filteredFoods,
  setFilteredFoods,
  foodChoice,
  setFoodChoice,
  mealNameChoice,
  setMealNameChoice,
  mealDescriptionChoice,
  setMealDescriptionChoice,
  handlePostMealEntry,
}) => {
  const [submitReady, setSubmitReady] = useState(false)
  const [addToMeals, setAddToMeals] = useState(false)
  const [foodSearch, setFoodSearch] = useState('')
  const [brandSearch, setBrandSearch] = useState('')

  const handleServingsChange = (a) => (e) => {
    const newFoodChoice = foodChoice.map((obj) => {
      if (obj._id === a) {
        return {
          ...obj,
          numServings: e.target.value,
        }
      } else {
        return obj
      }
    })
    setFoodChoice(newFoodChoice)
    checkIfSubmitReady(newFoodChoice)
  }

  const handleMealNameChange = (e) => {
    setMealNameChoice(e.target.value)
  }

  const handleMealDescriptionChange = (e) => {
    setMealDescriptionChoice(e.target.value)
  }

  function checkIfSubmitReady(arr) {
    console.log(arr)
    if (arr.length > 0 && arr.map((a) => a.numServings).every((b) => b.length > 0)) {
      setSubmitReady(true)
    } else {
      setSubmitReady(false)
    }
  }

  const handleAddToMealsChoice = (e) => {
    if (e.target.checked) {
      setAddToMeals(true)
    } else {
      setAddToMeals(false)
    }
  }

  const handleAddToChecked = (e) => {
    let item = filteredFoods.filter((a) => a._id === e.currentTarget.id)[0]
    item.numServings = 0
    setFoodChoice([...foodChoice, item])
    setFoodSearch('')
    setBrandSearch('')
    setFilteredFoods(foods)
  }

  const handleRemoveFromChecked = (e) => {
    const newChoice = foodChoice.filter((a) => a._id !== e.currentTarget.id)
    setFoodChoice(newChoice)
    checkIfSubmitReady(newChoice)
  }

  const handleFoodSearch = (e) => {
    setFoodSearch(e.target.value)
    setFilteredFoods(
      foods.filter(
        (a) =>
          a.name.toLowerCase().includes(e.target.value.toLowerCase()) &&
          a.brand.toLowerCase().includes(brandSearch.toLowerCase())
      )
    )
  }
  const handleBrandSearch = (e) => {
    setBrandSearch(e.target.value)
    setFilteredFoods(
      foods.filter(
        (a) =>
          a.brand.toLowerCase().includes(e.target.value.toLowerCase()) &&
          a.name.toLowerCase().includes(foodSearch.toLowerCase())
      )
    )
  }

  return (
    <div>
      <div
        style={{
          width: '96%',
          margin: '20px 0 0 2%',
          textAlign: 'center',
          fontSize: '14px',
        }}
      >
        {foodChoice.length > 0 &&
          foodChoice.map((a) => (
            <div
              key={a._id}
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div style={{ width: '90%' }}>
                <InputGroup style={{ height: '30px' }}>
                  <InputGroup.Text
                    style={{
                      width: '75%',
                      fontSize: '14px',
                      justifyContent: 'center',
                    }}
                  >
                    {Number(a.numServings) === 0
                      ? toTitleCase(a.name)
                      : `${a.numServings * a.servingSize} ${
                          a.servingSizeUnit
                        } of ${toTitleCase(a.name)}`}
                  </InputGroup.Text>
                  <Form.Control
                    style={{ fontSize: '12px' }}
                    placeholder='Servings...'
                    onChange={handleServingsChange(a._id)}
                    value={a.numServings === 0 ? '' : a.numServings}
                  />
                </InputGroup>
                <p style={{ marginTop: '10px' }}>{`${
                  a.calories * Number(a.numServings)
                } Calories, ${
                  a.proteinGrams * Number(a.numServings)
                }g Protein, ${a.carbsGrams * Number(a.numServings)}g Carbs, ${
                  a.fatGrams * Number(a.numServings)
                }g Fat`}</p>
              </div>
              <Button
                variant='outline-dark'
                style={{
                  width: '9%',
                  marginLeft: '1%',
                  padding: '0',
                  height: '35px',
                }}
                id={a._id}
                onClick={handleRemoveFromChecked}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </div>
          ))}
        {addToMeals && (
          <div>
            <InputGroup style={{ marginTop: '15px' }}>
              <InputGroup.Text
                id='inputGroup-sizing-default'
                style={{ width: '40%', fontSize: '14px' }}
              >
                Meal Name
              </InputGroup.Text>
              <Form.Control
                aria-label='Default'
                aria-describedby='inputGroup-sizing-default'
                placeholder='Type Meal Name Here...'
                style={{ fontSize: '14px' }}
                onChange={handleMealNameChange}
                value={mealNameChoice}
              />
            </InputGroup>
            <InputGroup style={{ marginTop: '15px' }}>
              <InputGroup.Text
                id='inputGroup-sizing-default'
                style={{ width: '40%', fontSize: '14px' }}
              >
                Meal Description
              </InputGroup.Text>
              <Form.Control
                aria-label='Default'
                aria-describedby='inputGroup-sizing-default'
                placeholder='Type Meal Description Here...'
                style={{ fontSize: '14px' }}
                onChange={handleMealDescriptionChange}
                value={mealDescriptionChoice}
              />
            </InputGroup>
          </div>
        )}
        {submitReady && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              height: '40px',
              margin: '25px 0 10px 0',
            }}
          >
            <Form style={{ marginTop: '10px' }}>
              <Form.Check
                reverse
                type={'checkbox'}
                label='Save As Meal?'
                id='default-checkbox'
                onClick={handleAddToMealsChoice}
              />
            </Form>
            <Button variant='outline-dark' onClick={handlePostMealEntry}>
              Submit Meal Entry
            </Button>
          </div>
        )}
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <FloatingLabel
            style={{ width: '40%', marginLeft: '15%' }}
            controlId='floatingInput'
            label='Search Foods...'
            className='mb-3'
          >
            <Form.Control
              onChange={handleFoodSearch}
              type='Food'
              placeholder='Search Foods...'
              value={foodSearch}
            />
          </FloatingLabel>
          <FloatingLabel
            style={{ width: '40%', marginLeft: '5%' }}
            controlId='floatingInput'
            label='Search Brands...'
            className='mb-3'
          >
            <Form.Control
              onChange={handleBrandSearch}
              type='Brand'
              placeholder='Search Brands...'
              value={brandSearch}
            />
          </FloatingLabel>
        </div>
        <ul
          style={{
            listStyleType: 'none',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            maxHeight: '250px',
            overflowY: 'scroll',
            margin: '0',
          }}
        >
          {filteredFoods
            .filter((c) => !foodChoice.map((b) => b._id).includes(c._id))
            .sort(
              (a, b) =>
                a.brand.localeCompare(b.brand) || a.name.localeCompare(b.name)
            )
            .map((a) => (
              <li
                key={a._id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '2px 2%',
                  width: '96%',
                  height: '40px',
                }}
              >
                <Button
                  variant='outline-dark'
                  style={{ width: '10%', marginLeft: '2%' }}
                  id={a._id}
                  onClick={handleAddToChecked}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Card
                  className='border-0'
                  style={{
                    width: '40%',
                    margin: '10px 0 10px 2%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {toTitleCase(a.name)}
                </Card>
                <Card
                  className='border-0'
                  style={{
                    width: '25%',
                    margin: '10px 0 10px 10%',
                    fontSize: '12px',
                    textAlign: 'center',
                    height: '20px',
                  }}
                >
                  {toTitleCase(a.brand)}
                </Card>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default AddFood
